/* line 1, /runap/frontend/app/sass/error500.scss */
body.page-500-full-page {
  overflow-x: hidden;
  padding: 20px;
  background-color: #fafafa !important; }
  /* line 6, /runap/frontend/app/sass/error500.scss */
  body.page-500-full-page div.page-500 {
    text-align: center;
    margin-top: 100px; }
    /* line 10, /runap/frontend/app/sass/error500.scss */
    body.page-500-full-page div.page-500 div.number {
      display: inline-block;
      letter-spacing: -10px;
      line-height: 128px;
      font-size: 128px;
      font-weight: 300;
      color: #ec8c8c;
      text-align: right; }
    /* line 20, /runap/frontend/app/sass/error500.scss */
    body.page-500-full-page div.page-500 div.details {
      margin-left: 40px;
      display: inline-block;
      text-align: left; }
      /* line 25, /runap/frontend/app/sass/error500.scss */
      body.page-500-full-page div.page-500 div.details input {
        background-color: #ffffff; }

@media (max-width: 480px) {
  /* line 34, /runap/frontend/app/sass/error500.scss */
  div.page-500 div.number,
  div.page-500 div.details {
    text-align: center;
    margin-left: 0px; } }
