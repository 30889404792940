/* here you can put your own css to customize and override the theme */
/* line 2, /runap/frontend/app/sass/app.scss */
.logo-default {
  position: relative;
  top: -20px;
  height: 60px; }

/* line 10, /runap/frontend/app/sass/app.scss */
.btn i[class^="icon-"] {
  vertical-align: -1px; }

/* line 18, /runap/frontend/app/sass/app.scss */
div.btn-group label.btn i[class^="icon-"] {
  vertical-align: -1px; }

/* line 25, /runap/frontend/app/sass/app.scss */
div.btn-group > .btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

/* line 33, /runap/frontend/app/sass/app.scss */
div.mt-element-list > div.mt-list-head.dark {
  background-color: #2f353b; }

/* line 38, /runap/frontend/app/sass/app.scss */
div.note {
  border-left-width: 5px;
  margin-bottom: 10px; }

/* line 44, /runap/frontend/app/sass/app.scss */
dl dt:not(:first-child) {
  margin-top: 10px; }

/* line 56, /runap/frontend/app/sass/app.scss */
div.page-header > div.page-header-top div.top-menu ul.navbar-nav > li.dropdown {
  cursor: pointer;
  padding: 17px 10px 8px 10px; }
  /* line 60, /runap/frontend/app/sass/app.scss */
  div.page-header > div.page-header-top div.top-menu ul.navbar-nav > li.dropdown > i {
    color: #3366cb !important;
    font-size: 19px; }

/* line 68, /runap/frontend/app/sass/app.scss */
div.page-header > div.page-header-top .page-logo {
  width: 600px;
  height: 80px;
  margin-top: 5px; }

/* line 75, /runap/frontend/app/sass/app.scss */
div.page-header > div.page-header-menu {
  background-color: #F2F2F2 !important;
  border-bottom: 0.188rem solid #004884 !important; }
  /* line 82, /runap/frontend/app/sass/app.scss */
  div.page-header > div.page-header-menu div.hor-menu ul.navbar-nav li.menu-dropdown a {
    color: #fff; }
  /* line 89, /runap/frontend/app/sass/app.scss */
  div.page-header > div.page-header-menu .search-form {
    width: 20em;
    background-color: transparent;
    padding-top: 5px; }
    /* line 94, /runap/frontend/app/sass/app.scss */
    div.page-header > div.page-header-menu .search-form div.input-group {
      width: 100%;
      background-color: #fff; }
      /* line 98, /runap/frontend/app/sass/app.scss */
      div.page-header > div.page-header-menu .search-form div.input-group input.form-control {
        background-color: transparent; }
      /* line 102, /runap/frontend/app/sass/app.scss */
      div.page-header > div.page-header-menu .search-form div.input-group .search {
        width: 20em; }

/* line 110, /runap/frontend/app/sass/app.scss */
div.page-prefooter {
  background: #84bc3d;
  color: #F3FFBD; }
  /* line 114, /runap/frontend/app/sass/app.scss */
  div.page-prefooter h2 {
    color: #fff; }
    /* line 117, /runap/frontend/app/sass/app.scss */
    div.page-prefooter h2 a {
      color: #fff; }
  /* line 122, /runap/frontend/app/sass/app.scss */
  div.page-prefooter a {
    color: #F3FFBD; }

/* line 127, /runap/frontend/app/sass/app.scss */
div.page-footer {
  background: #eff3f8;
  text-align: left;
  color: #3366cb !important; }
  /* line 132, /runap/frontend/app/sass/app.scss */
  div.page-footer.logos {
    background-color: #fff;
    text-align: center; }
    /* line 136, /runap/frontend/app/sass/app.scss */
    div.page-footer.logos img {
      height: 64px;
      margin: 0 10px; }

/* line 146, /runap/frontend/app/sass/app.scss */
form div.form-body textarea.form-control {
  height: 150px; }

/* line 151, /runap/frontend/app/sass/app.scss */
form div.form-body div.form-group.form-md-line-input .text-error {
  position: absolute;
  color: #e73d4a;
  font-size: 13px;
  margin: 2px 0 0; }

/* line 160, /runap/frontend/app/sass/app.scss */
form div.form-actions {
  margin-top: 40px;
  margin-bottom: 20px; }

/* line 167, /runap/frontend/app/sass/app.scss */
div.contact-background {
  height: 350px;
  background-image: url("../img/contact-bck.jpg"); }

/* line 172, /runap/frontend/app/sass/app.scss */
div.sign-up-background {
  height: 350px;
  background-image: url("../img/sign-up-bck.jpg"); }

/**
 * Porlets
 */
/* line 182, /runap/frontend/app/sass/app.scss */
div.portlet.about-text {
  padding: 0; }
  /* line 185, /runap/frontend/app/sass/app.scss */
  div.portlet.about-text > h4 {
    background-color: #d9534f;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    padding: 0.85em 20px;
    margin: 0; }
  /* line 194, /runap/frontend/app/sass/app.scss */
  div.portlet.about-text div.portlet-body {
    padding: 12px 20px 15px 20px; }

/* line 202, /runap/frontend/app/sass/app.scss */
div.portlet.light > div.portlet-title > div.caption > i {
  margin-top: 4px; }

/**
 * Swiper
 */
/* line 214, /runap/frontend/app/sass/app.scss */
div.swiper-container {
  width: 100%;
  min-height: 150px; }
  /* line 220, /runap/frontend/app/sass/app.scss */
  div.swiper-container > div.swiper-wrapper > div.swiper-slide {
    height: 480px;
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center; }
    /* line 240, /runap/frontend/app/sass/app.scss */
    div.swiper-container > div.swiper-wrapper > div.swiper-slide > img {
      max-height: 480px; }

/**
 * Superslides
 */
/* line 255, /runap/frontend/app/sass/app.scss */
div.slides > div.slides-control > ul.slides-container > li div.container {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px; }
  /* line 263, /runap/frontend/app/sass/app.scss */
  div.slides > div.slides-control > ul.slides-container > li div.container > a {
    color: #fff; }

/* line 271, /runap/frontend/app/sass/app.scss */
div.slides > nav.slides-navigation {
  top: 50%; }
  /* line 274, /runap/frontend/app/sass/app.scss */
  div.slides > nav.slides-navigation a {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
    display: block;
    text-decoration: none;
    border: 3px solid #fff;
    color: white;
    font-weight: bold;
    font-size: 26px;
    margin: 0 25px;
    text-shadow: 0 1px 1px #000;
    text-align: center;
    height: 40px;
    width: 40px;
    top: -40px;
    padding: 1px;
    -webkit-box-shadow: 0 1px 1px black;
    -moz-box-shadow: 0 1px 1px black;
    box-shadow: 0 1px 1px black;
    -webkit-transition: background 0.15s ease;
    -moz-transition: background 0.15s ease;
    -o-transition: background 0.15s ease;
    transition: background 0.15s ease; }
    /* line 301, /runap/frontend/app/sass/app.scss */
    div.slides > nav.slides-navigation a:hover {
      background: rgba(0, 0, 0, 0.4); }
    /* line 306, /runap/frontend/app/sass/app.scss */
    div.slides > nav.slides-navigation a.prev > i {
      margin-left: -3px; }
    /* line 312, /runap/frontend/app/sass/app.scss */
    div.slides > nav.slides-navigation a.next > i {
      margin-right: -3px; }

/* line 319, /runap/frontend/app/sass/app.scss */
div.slides > nav.slides-pagination {
  top: 5px;
  bottom: inherit; }
  /* line 323, /runap/frontend/app/sass/app.scss */
  div.slides > nav.slides-pagination a {
    width: 15px;
    height: 15px;
    margin: 5px 2px;
    border-color: white;
    -webkit-box-shadow: 0 1px 1px black;
    -moz-box-shadow: 0 1px 1px black;
    box-shadow: 0 1px 1px black; }
    /* line 332, /runap/frontend/app/sass/app.scss */
    div.slides > nav.slides-pagination a.current {
      background: white; }

/* line 339, /runap/frontend/app/sass/app.scss */
.contrast {
  background: rgba(0, 0, 0, 0.3);
  padding: 1px 10px;
  -webkit-box-shadow: 0 0 23px 5px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 0 23px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 23px 5px rgba(0, 0, 0, 0.4);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px; }

/**
 * Mapa
 */
/* line 356, /runap/frontend/app/sass/app.scss */
.table {
  font-size: small; }

/* line 360, /runap/frontend/app/sass/app.scss */
div#map {
  width: 100%;
  height: 500px; }

/**
 * amCharts
 */
/* line 370, /runap/frontend/app/sass/app.scss */
div.amcharts-chart-div a {
  display: none !important; }

/**
 * Map
 */
/* line 379, /runap/frontend/app/sass/app.scss */
div.angular-google-map-container {
  height: 350px; }
  /* line 382, /runap/frontend/app/sass/app.scss */
  div.angular-google-map-container img {
    max-width: none; }

/* line 388, /runap/frontend/app/sass/app.scss */
div.leaflet-top {
  z-index: 900; }

/**
 * Area charts
 */
/* line 397, /runap/frontend/app/sass/app.scss */
div.areas-charts table.area {
  width: 100%; }
  /* line 401, /runap/frontend/app/sass/app.scss */
  div.areas-charts table.area:not(:first-child) {
    margin-top: 20px; }
  /* line 405, /runap/frontend/app/sass/app.scss */
  div.areas-charts table.area:last-child {
    margin-bottom: 30px; }
  /* line 411, /runap/frontend/app/sass/app.scss */
  div.areas-charts table.area tr.no-chart > td {
    padding-top: 8px; }
  /* line 416, /runap/frontend/app/sass/app.scss */
  div.areas-charts table.area tr > td {
    line-height: 0.9em; }

/**
 * Highcharts
 */
/* line 428, /runap/frontend/app/sass/app.scss */
div.btn-group.chart-buttons > label {
  text-transform: none;
  padding: 6px 8px;
  margin-top: 2px;
  margin-bottom: 2px; }

/**
 * Overlay
 */
/* line 440, /runap/frontend/app/sass/app.scss */
div.overlay-outer {
  position: relative; }
  /* line 443, /runap/frontend/app/sass/app.scss */
  div.overlay-outer div.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    text-align: center;
    opacity: 0;
    -webkit-transition: opacity .25s ease;
    -moz-transition: opacity .25s ease;
    padding: 45px 0 66px 0;
    z-index: 10; }
    /* line 457, /runap/frontend/app/sass/app.scss */
    div.overlay-outer div.overlay div.options {
      position: absolute;
      top: 45%;
      width: 100%; }
      /* line 463, /runap/frontend/app/sass/app.scss */
      div.overlay-outer div.overlay div.options > button {
        font-size: 2.5em; }
  /* line 470, /runap/frontend/app/sass/app.scss */
  div.overlay-outer:hover div.overlay {
    opacity: 1; }

/**
 * About
 */
/* line 479, /runap/frontend/app/sass/app.scss */
.about-header {
  height: 500px;
  background-image: url("../img/rails.jpg");
  background-position: center;
  text-align: center;
  margin: 0 -20px; }
  /* line 486, /runap/frontend/app/sass/app.scss */
  .about-header h1 {
    color: #fff;
    font-size: 55px;
    font-weight: 600;
    margin-top: 130px;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2); }
  /* line 494, /runap/frontend/app/sass/app.scss */
  .about-header h2 {
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 40px; }

/***
Horizontal Timeline
***/
/* PLUGIN CSS */
/* line 510, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline {
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s; }

/* line 516, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline::before {
  /* never visible - this is used in jQuery to check the current MQ */
  content: 'mobile';
  display: none; }

/* line 521, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline.loaded {
  /* show the timeline after events position has been set (using JavaScript) */
  opacity: 1; }

/* line 525, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .timeline {
  position: relative;
  height: 100px;
  width: 90%;
  max-width: 800px;
  margin: 0 auto; }

/* line 532, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events-wrapper {
  position: relative;
  height: 100%;
  margin: 0 40px;
  overflow: hidden; }

/* line 538, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events-wrapper::after, .cd-horizontal-timeline .events-wrapper::before {
  /* these are used to create a shadow effect at the sides of the timeline */
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 20px; }

/* line 547, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events-wrapper::before {
  left: 0;
  background-image: -webkit-linear-gradient(left, #f8f8f8, rgba(248, 248, 248, 0));
  background-image: linear-gradient(to right, #f8f8f8, rgba(248, 248, 248, 0)); }

/* line 552, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events-wrapper::after {
  right: 0;
  background-image: -webkit-linear-gradient(right, #f8f8f8, rgba(248, 248, 248, 0));
  background-image: linear-gradient(to left, #f8f8f8, rgba(248, 248, 248, 0)); }

/* line 557, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events {
  /* this is the grey line/timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 49px;
  height: 2px;
  /* width will be set using JavaScript */
  background: #dfdfdf;
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  transition: transform 0.4s; }

/* line 570, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .filling-line {
  /* this is used to create the green line filling the timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #7b9d6f;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s; }

/* line 593, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events a {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 1.3rem;
  padding-bottom: 15px;
  color: #383838;
  /* fix bug on Safari - text flickering while timeline translates */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0); }

/* line 608, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events a::after {
  /* this is used to create the event spot */
  content: '';
  position: absolute;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  -moz-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s; }

/* line 627, /runap/frontend/app/sass/app.scss */
.no-touch .cd-horizontal-timeline .events a:hover::after {
  background-color: #7b9d6f;
  border-color: #7b9d6f; }

/* line 631, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events a.selected {
  pointer-events: none; }

@media only screen and (min-width: 1100px) {
  /* line 635, /runap/frontend/app/sass/app.scss */
  .cd-horizontal-timeline::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'desktop'; } }

/* line 639, /runap/frontend/app/sass/app.scss */
.cd-timeline-navigation a {
  /* these are the left/right arrows to navigate the timeline */
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  /* replace text with an icon */
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s; }

/* line 663, /runap/frontend/app/sass/app.scss */
.cd-timeline-navigation a.prev {
  left: 0; }

/* line 666, /runap/frontend/app/sass/app.scss */
.cd-timeline-navigation a.next {
  right: 0; }

/* line 669, /runap/frontend/app/sass/app.scss */
.cd-timeline-navigation a.inactive {
  cursor: not-allowed; }

/* line 672, /runap/frontend/app/sass/app.scss */
.cd-timeline-navigation a.inactive::after {
  background-position: 0 -16px; }

/* line 675, /runap/frontend/app/sass/app.scss */
.no-touch .cd-timeline-navigation a.inactive:hover {
  border-color: #dfdfdf; }

/* line 678, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events-content {
  position: relative;
  width: 100%;
  margin: 10px 0 0 0;
  overflow: hidden;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  transition: height 0.4s; }

/* line 687, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events-content > ol > li {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  padding: 0;
  opacity: 0;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out; }

/* line 707, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events-content > ol > li.selected {
  /* visible event content */
  position: relative;
  z-index: 2;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0); }

/* line 718, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events-content > ol > li.enter-right, .cd-horizontal-timeline .events-content > ol > li.leave-right {
  -webkit-animation-name: cd-enter-right;
  -moz-animation-name: cd-enter-right;
  animation-name: cd-enter-right; }

/* line 723, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events-content > ol > li.enter-left, .cd-horizontal-timeline .events-content > ol > li.leave-left {
  -webkit-animation-name: cd-enter-left;
  -moz-animation-name: cd-enter-left;
  animation-name: cd-enter-left; }

/* line 728, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events-content > ol > li.leave-right, .cd-horizontal-timeline .events-content > ol > li.leave-left {
  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
  animation-direction: reverse; }

/* line 733, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events-content > ol > li {
  margin: 0 auto; }

/* line 736, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events-content em {
  display: block;
  font-style: italic;
  margin: 10px auto; }

/* line 741, /runap/frontend/app/sass/app.scss */
.cd-horizontal-timeline .events-content em::before {
  content: '- '; }

@-webkit-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%); } }

@-moz-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%); }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%); } }

@keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%); } }

@-webkit-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%); } }

@-moz-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%); }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%); } }

@keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%); } }

/* METRONIC EXTENDED CSS */
/* line 809, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal {
  font-size: 14px; }

/* line 811, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal ol, .mt-timeline-horizontal ul {
  list-style: none; }

/* line 813, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal blockquote, .mt-timeline-horizontal q {
  quotes: none; }

/* line 815, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal blockquote:before, .mt-timeline-horizontal blockquote:after,
.mt-timeline-horizontal q:before, .mt-timeline-horizontal q:after {
  content: '';
  content: none; }

/* line 819, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal table {
  border-collapse: collapse;
  border-spacing: 0; }

/* line 822, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .timeline {
  width: 100%;
  max-width: 100%; }

/* line 825, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .timeline:before {
  background: transparent; }

/* line 827, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .timeline .events-wrapper .events a:after {
  background-color: transparent;
  border: 2px solid; }

/* line 830, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .timeline .events-wrapper .events a.selected:after {
  background-color: #fff !important; }

/* line 832, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .timeline .events-wrapper .events a:hover, .mt-timeline-horizontal .timeline .events-wrapper .events a:focus {
  text-decoration: none; }

/* line 834, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .timeline .events-wrapper:before, .mt-timeline-horizontal .timeline .events-wrapper:after {
  background-image: none; }

/* line 836, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .timeline .mt-ht-nav-icon li a {
  border-radius: 50% !important; }

/* line 838, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .timeline .mt-ht-nav-icon li a i {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateX(50%) translateY(-50%);
  width: 10px; }

/* line 844, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .timeline .mt-ht-nav-icon li:first-child a i {
  left: -2px; }

/* line 846, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .timeline.mt-timeline-square .events a:after {
  border-radius: 0 !important; }

/* line 848, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .timeline.mt-timeline-square .mt-ht-nav-icon li a {
  border-radius: 0 !important; }

/* line 850, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol {
  padding: 0; }

/* line 852, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol li .mt-title {
  margin-top: 15px;
  float: left;
  width: 60%; }

/* line 856, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol li .mt-title h2 {
  margin: 0;
  opacity: 0.8;
  filter: alpha(opacity=80);
  font-size: 18px;
  font-weight: 600; }

/* line 862, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol li .mt-author {
  float: right;
  position: relative;
  text-align: right;
  width: 40%; }

/* line 867, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol li .mt-author > .mt-avatar {
  float: right;
  margin-left: 15px; }

/* line 870, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol li .mt-author > .mt-author-name {
  margin-top: 5px; }

/* line 872, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol li .mt-author > .mt-author-name a {
  opacity: 0.9;
  filter: alpha(opacity=90);
  font-size: 15px;
  font-weight: 600; }

/* line 877, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol li .mt-author > .mt-author-name a:hover, .mt-timeline-horizontal .events-content ol li .mt-author > .mt-author-name a:focus {
  text-decoration: none; }

/* line 879, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol li .mt-author > .mt-author-datetime {
  font-size: 13px; }

/* line 881, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol li .mt-author > .mt-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  overflow: hidden; }

/* line 886, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol li .mt-author > .mt-avatar > img {
  width: 100%;
  height: auto; }

/* line 889, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol li .mt-content {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid;
  clear: both;
  line-height: 1.7em; }

/* line 895, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol li .mt-content > p {
  opacity: 0.7;
  filter: alpha(opacity=70); }

/* line 898, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol li .mt-content img.pull-left {
  margin: 0 15px 15px 0; }

/* line 900, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol li .mt-content img.pull-right {
  margin: 0 0 15px 15px; }

/* line 902, /runap/frontend/app/sass/app.scss */
.mt-timeline-horizontal .events-content ol li .mt-content .btn-group .dropdown-menu {
  margin-right: 5px; }

@media (max-width: 480px) {
  /* line 906, /runap/frontend/app/sass/app.scss */
  .mt-timeline-horizontal .events-content ol li .mt-title {
    width: 100%; }
  /* line 908, /runap/frontend/app/sass/app.scss */
  .mt-timeline-horizontal .events-content ol li .mt-author {
    width: 100%;
    margin-top: 15px;
    text-align: left; }
  /* line 912, /runap/frontend/app/sass/app.scss */
  .mt-timeline-horizontal .events-content ol li .mt-author > .mt-avatar {
    float: left;
    margin-right: 15px;
    margin-left: 0; }
  /* line 916, /runap/frontend/app/sass/app.scss */
  .mt-timeline-horizontal .events-content ol li .mt-author > .mt-author-name {
    margin-top: 10px; }
  /* line 918, /runap/frontend/app/sass/app.scss */
  .mt-timeline-horizontal .btn.pull-right {
    float: none !important;
    margin: 0 !important; } }
