/* line 1, /runap/frontend/app/sass/error404.scss */
body.page-404-3 {
  background-color: #000;
  background-image: url("../img/earth.jpg");
  background-position: right bottom;
  background-repeat: no-repeat; }
  /* line 7, /runap/frontend/app/sass/error404.scss */
  body.page-404-3 div.error-404 {
    color: #fff;
    text-align: left;
    padding: 70px 20px 0; }
  /* line 13, /runap/frontend/app/sass/error404.scss */
  body.page-404-3 h1 {
    color: #fff;
    font-size: 130px;
    line-height: 160px; }
  /* line 19, /runap/frontend/app/sass/error404.scss */
  body.page-404-3 h2 {
    color: #fff;
    font-size: 30px;
    margin-bottom: 30px; }
  /* line 25, /runap/frontend/app/sass/error404.scss */
  body.page-404-3 p {
    color: #fff;
    font-size: 16px; }

@media (max-width: 480px) {
  /* line 33, /runap/frontend/app/sass/error404.scss */
  body.page-404-3 div.error-404 {
    text-align: left;
    padding-top: 10px; } }
