@media (max-width: 991px) {
  /* line 2, /runap/frontend/app/sass/app-mobile.scss */
  div.mobil-vspace {
    margin-top: 15px; }
  /* line 7, /runap/frontend/app/sass/app-mobile.scss */
  div.page-header > div.page-header-menu {
    padding-bottom: 20px; } }

@media screen and (max-width: 767px) {
  /* line 14, /runap/frontend/app/sass/app-mobile.scss */
  .slides-navigation {
    display: none; } }
