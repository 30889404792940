/* line 3, /runap/frontend/app/sass/header.scss */
div.page-header > div.page-header-top div.pnn-header-container1 {
  display: flex;
  justify-content: center;
  background-color: #36c;
  padding: 8px 0; }
  /* line 9, /runap/frontend/app/sass/header.scss */
  div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    align-items: center; }
    /* line 16, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane {
      display: flex;
      margin-left: 22px; }
      /* line 20, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-govco {
        align-self: center;
        height: 40px;
        content: url(https://components.parquesnacionales.gov.co/shared/logo-govco.svg); }
      /* line 26, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-separator {
        align-self: center;
        height: 44px;
        border-left: 1px solid #e5eefb;
        margin-right: 5px; }
      /* line 33, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-runap {
        align-self: center;
        display: none;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 8px;
        max-width: 300px;
        background-color: #fff;
        padding: 5px 5px 3px 5px;
        margin-left: 10px; }
        /* line 44, /runap/frontend/app/sass/header.scss */
        div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-runap > div.logo-runap-img1 {
          height: 25px;
          content: url(/img/logos/runap1.png); }
        /* line 49, /runap/frontend/app/sass/header.scss */
        div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-runap > div.logo-runap-img2 {
          height: 25px;
          content: url(/img/logos/runap2.png); }
        /* line 54, /runap/frontend/app/sass/header.scss */
        div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-runap > div.break {
          flex-basis: 100%;
          height: 0; }
        /* line 59, /runap/frontend/app/sass/header.scss */
        div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-runap > div.logo-runap-text {
          font-size: 12px;
          font-weight: bold;
          color: #000; }
    /* line 67, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.right-pane {
      margin-right: 25px; }
      /* line 70, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.right-pane > a.menu-toggler {
        align-self: center; }

/* line 77, /runap/frontend/app/sass/header.scss */
div.page-header > div.page-header-top div.pnn-header-container2 {
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 2rem 0; }
  /* line 83, /runap/frontend/app/sass/header.scss */
  div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;
    align-items: center;
    width: 100%; }
    /* line 91, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.left-pane {
      display: flex;
      row-gap: 20px;
      margin-left: 23px; }
      /* line 96, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-potencia-vida {
        height: 50px;
        content: url(https://components.parquesnacionales.gov.co/shared/potencia-vida.png);
        margin-right: 5px;
        margin-left: 5px; }
      /* line 103, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-entidad {
        height: 50px;
        content: url(https://components.parquesnacionales.gov.co/shared/logo-pnn.png);
        margin-right: 15px;
        margin-left: 11px; }
    /* line 111, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.right-pane {
      margin-left: 35px;
      margin-right: 35px; }
      /* line 115, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.right-pane > div.search-form {
        margin-top: 5px; }

/* line 128, /runap/frontend/app/sass/header.scss */
div.page-header div.page-header-menu > div.container > div.hor-menu > ul.navbar-nav > li.menu-dropdown a {
  color: #004884; }

@media (min-width: 768px) {
  /* line 161, /runap/frontend/app/sass/header.scss */
  div.page-header > div.page-header-top div.pnn-header-container1 {
    padding: 5px 0; }
    /* line 168, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-runap {
      display: flex !important; } }

@media (min-width: 992px) {
  /* line 188, /runap/frontend/app/sass/header.scss */
  div.page-header > div.page-header-top div.pnn-header-container1 {
    justify-content: center !important;
    padding: 5px 0; }
    /* line 192, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container {
      width: 940px !important; }
      /* line 196, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane {
        margin-left: 10px !important; }
        /* line 199, /runap/frontend/app/sass/header.scss */
        div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-runap {
          display: flex !important; }
      /* line 204, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.right-pane {
        margin-right: 20px !important; }
  /* line 210, /runap/frontend/app/sass/header.scss */
  div.page-header > div.page-header-top div.pnn-header-container2 {
    justify-content: center !important; }
    /* line 213, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container {
      width: 940px !important; }
      /* line 217, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.left-pane {
        margin-left: 10px !important; }
      /* line 221, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.right-pane {
        margin-right: 20px !important; } }

@media (min-width: 1200px) {
  /* line 234, /runap/frontend/app/sass/header.scss */
  div.page-header > div.page-header-top div.pnn-header-container1 {
    justify-content: center !important;
    padding: 5px 0; }
    /* line 238, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container {
      width: 1140px !important; }
      /* line 242, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane {
        margin-left: 10px !important; }
        /* line 245, /runap/frontend/app/sass/header.scss */
        div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-runap {
          display: flex !important; }
      /* line 250, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.right-pane {
        margin-right: 20px !important; }
  /* line 256, /runap/frontend/app/sass/header.scss */
  div.page-header > div.page-header-top div.pnn-header-container2 {
    justify-content: center !important; }
    /* line 259, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container {
      width: 1140px !important; }
      /* line 263, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.left-pane {
        margin-left: 10px !important; }
      /* line 267, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.right-pane {
        margin-right: 20px !important; } }
